/* eslint-disable no-nested-ternary */
import './scss/index.scss';
//import 'react-table/react-table.css';
import React, { Component } from 'react';
import { ConnectedRouter as Router } from 'connected-react-router';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IdleTimer from 'react-idle-timer';
import { IntlProvider } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import {
  AmityUiKitProvider,
} from "@rg-npmjs-dev/rg-amity-social-ui";
import actions from './actions';
import api from './api';
import { history } from './store';
import * as storage from './persistence/storage';
import enMessages from './locale/en.json';
import {
  IS_MAINTENANCE_MODE,
  EXTRA_LOGOUT_DURATION,
  EXPIRE_LOGOUT_DURATION,
  INITIAL_WARNING_DURATION,
  IMPERSONATION_LOGOUT_DURATION,
  AMITY_PUBLIC_KEY
} from './constants';
import localstring from './services/Localization';
import App from './routes/App';
import LoginRoutes from './routes/Login/LoginRoutes';
import CookieWarning from './components/Dialogs/CookieWarning';
import UnsupportedBrowser from './routes/UnsupportedBrowser';
import LoadingIndicator from './components/LoadingIndicator';
import eventTracking from './utils/event-tracking';
import getPage from './utils/getPage';
import { REALM_THEME } from './routes/Messages/components/REALM_THEME';

export class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inactive: false,
      timeoutEnabled: true,
      isLoaded: false,
      isImpersonation: this.props.currentUser?.impersonation || false
    };
    this._endResizeTimeout = 300;
    this._logoutTimerID = null;
    this._pageResizeTimeout = null;
    this._isMounted = false;
    this._delay = null;
  }

  componentDidMount() {
    // log out user if they were gone for longer than expire time
    // or if maintenance mode has been activated
    if (storage.get('lastUpdated')) {
      if (
        Date.now() >
          Number(storage.get('lastUpdated')) + EXPIRE_LOGOUT_DURATION ||
        IS_MAINTENANCE_MODE
      ) {
        if (storage.get('acceptCookies')) {
          storage.clear();
          storage.put('acceptCookies', 'true');
        } else {
          storage.clear();
        }
        const { router } = this.props;
        const path = router?.location?.pathname || '/login';
        const search = router?.location?.search || '';
        const redirect = path + search;
        setTimeout(() => {
          window.location.replace(redirect);
        }, 0);
      }
    }
    this._isMounted = true;
    window.addEventListener('resize', this._onResize, false);
    this.bringInPage();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._delay && !this.state.isImpersonation) {
      clearTimeout(this._delay);
      this._delay = null;
    }
    window.removeEventListener('resize', this._onResize, false);
    window.removeEventListener('scroll', this._onScroll, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.session.currentUser?.impersonation !==
      this.props.session.currentUser?.impersonation
    ) {
      this.setState({
        isImpersonation: this.props.session.currentUser?.impersonation || false
      });
      this.setupImpersonationLogoutTimer();
    }
    if (prevProps.session.amityToken && prevProps.session.amityToken !== this.props.session.amityToken) {
      // alert(`old token: ${prevProps.session.amityToken} | new token: ${this.props.session.amityToken}`)
      setTimeout(() => {
        window.location.reload(true);
       }, 500);
    }
  }

  bringInPage = () => {
    // if there is a reload, this will delay things a bit
    // but will have no bearing on the overall page load
    if (!this.state.isLoaded) {
      this._delay = setTimeout(() => {
        this.setState({
          isLoaded: true
        });
      }, 1000);
    }
  };

  onIdle = () => {
    if (this.state.timeoutEnabled && this._isMounted) {
      this.setState({
        inactive: true
      });
      this.setupLogoutTimer();
    }
  };

  // RESIZING
  _onResize = () => {
    if (this.props.application.isResizing) {
      return null;
    }
    this.props.actions.onResize(true);
    if (!this.props.session.currentUser?.impersonation) {
      clearTimeout(this._pageResizeTimeout);
    }
    this._pageResizeTimeout = setTimeout(
      this._onResizeEnd,
      this._endResizeTimeout
    );
    return null;
  };

  _onResizeEnd = () => {
    if (!this.props.session.currentUser?.impersonation) {
      clearTimeout(this._pageResizeTimeout);
    }
    this._pageResizeTimeout = null;
    this.props.actions.onResize(false);
  };

  setupActivityInterval = () => {
    this._logoutIntervalID = setInterval(async () => {
      await api.get('/ping');
    }, EXTRA_LOGOUT_DURATION + INITIAL_WARNING_DURATION);
  };

  setupLogoutTimer = () => {
    this._logoutTimerID = setTimeout(() => {
      this.handleLogout();
    }, EXTRA_LOGOUT_DURATION);
  };

  setupImpersonationLogoutTimer = () => {
    console.log('setupImpersonationLogoutTimer');
    this._logoutTimerID = setTimeout(() => {
      this.handleLogout();
    }, IMPERSONATION_LOGOUT_DURATION);
  };

  clearLogoutTimer = () => {
    if (
      this._logoutTimerID !== null &&
      !this.props.session.currentUser?.impersonation
    ) {
      clearTimeout(this._logoutTimerID);
      this._logoutTimerID = null;
    }
  };

  registerLogin = async () => {
    this.props.actions.getPersonData();
    await api.get('/user/reloggin');
    // force user/me
  };

  getPage = () => {
    const urlParts = document.URL.split('//');
    return urlParts[3] || 'Dashboard';
  };

  stayActive = () => {
    if (this._isMounted) {
      this.setState({
        inactive: false
      });
    }
    this.clearLogoutTimer();
    this.registerLogin();
    eventTracking(`${getPage()}`, 'Continue Active', 'Inactivity Dialog');
    console.log('stay active');
  };

  onSocialDisconnected = (props) => {
    const p = props;
    console.log('social system event: ', p, ` time: ${new Date()} `)


    if(props.event == 'disconnected' && props.oldValue !== 'connected'){
      console.log('fetching new Amity token');
      this.props.actions.getAmityToken(true);
      
      setTimeout(() => {
       // window.location.reload(true);
      }, 500);

    }
  }

  onSocialStatusChange = (props) => {
    const {oldValue, newValue} = props;
    console.log('social system event: ', props, ` time: ${new Date()} `)
  }

  postRenderers = (props) => {
    const p = props;
    console.log('social system event. Post Renderer: ', p, ` time: ${new Date()} `)
  }

  actionHandlers = (props) => {
    const p = props;
    console.log('social system event. Action Handler: ', p, ` time: ${new Date()} `)
  }

  handleLogout = () => {
    if (this._isMounted) {
      eventTracking(`${getPage()}`, 'Logout', 'Inactivity Dialog');
      this.setState(
        {
          inactive: false
        },
        () => {
          window.location.replace('/logout');
        }
      );
    }
  };

  render() {
    const { actions, application, router, session } = this.props;
    const browserVersion = Number.parseInt(application.browserVersion, 10);
    const userFullName = `${session.currentUser?.firstName} ${session.currentUser?.lastName}`;
    if (!application.isMobile && application.isIE && browserVersion < 11) {
      return <UnsupportedBrowser />;
    }
    if (!session.isAuthenticated) {
      return (
        <Router history={history}>
          <>
            <LoginRoutes actions={actions} session={session} />
            {!session.acceptCookies && (
              <CookieWarning acceptCookies={actions.acceptCookies} />
            )}
          </>
        </Router>
      );
    }
    return (
      <IntlProvider locale={application.locale} messages={enMessages}>
         <AmityUiKitProvider
      //    theme={REALM_THEME}
          key={session.currentUser?._id}
          apiKey={AMITY_PUBLIC_KEY}
          authToken={session.amityToken}
          apiEndpoint="https://api.us.amity.co"
          userId={session.currentUser?._id}
          displayName={userFullName}
          postRenderers={this.postRenderers}
          actionHandlers={this.actionHandlers}
          onDisconnected={this.onSocialDisconnected}
          onConnectionStatusChange={this.onSocialStatusChange}
        >
        <>
          <IdleTimer
            debounce={250}
            element={window.document}
            onIdle={this.onIdle}
            ref={ref => {
              this.idleTimer = ref;
            }}
            timeout={INITIAL_WARNING_DURATION}
          />
          {this.state.isLoaded ? (
            <Router history={history}>
              <App
                actions={actions}
                application={application}
                router={router}
                session={session}
                impersonationLogout={this.setupImpersonationLogoutTimer}
              />
            </Router>
          ) : (
            <div className="realm--loading-container">
              <LoadingIndicator />
            </div>
          )}
          {session.isAuthenticated && (
            <Dialog
              style={{ alignItems: 'center', justifyContent: 'center' }}
              open={this.state.inactive}
            >
              <DialogTitle id="add-agent-dialog-title">
                {localstring.inactivity_warning}
              </DialogTitle>
              <DialogContent className="realm--modal-content">
                {localstring.inactivity_message}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleLogout} color="default">
                  {localstring.menu1_user_logout}
                </Button>
                <Button
                  onClick={this.stayActive}
                  color="primary"
                  variant="contained"
                >
                  {localstring.onboarding.continue}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {!session.acceptCookies && (
            <CookieWarning
              acceptCookies={actions.acceptCookies}
              lanaguage={this.props.application.locale}
            />
          )}
        </>
        </AmityUiKitProvider>
      </IntlProvider>
    );
  }
}

Application.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  router: PropTypes.object,
  session: PropTypes.object
};

export default connect(
  state => ({
    application: state.application,
    router: state.router,
    session: state.session
  }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) })
)(Application);
