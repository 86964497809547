import { getStageByDomain } from './utils/get-stage-by-domain';
import { extractSubDomain } from './utils/get-domain';
import appVersion from './appVersion.json' //new constants

export const STAGE = getStageByDomain(); // IF YOU NEED TO TEST OTHER ENVIROMENTS, HARD CODE THIS LINE TO dev, staging, or prod
export const SUBDOMAIN = extractSubDomain(); // FOR MIXPANEL AND AMITY
// console.log('stage is ', STAGE);
// console.log('subDomain is', SUBDOMAIN);

// API API_ROOT
const API_ROOTS = {
  local: 'http://localhost:8010/api/v1',
  dev: 'https://f9i8fscvgi.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  staging:
    'https://lkvhry3xr2.execute-api.us-east-1.amazonaws.com/stage/api/v1',
  prod: 'https://48dnz9g9w3.execute-api.us-east-1.amazonaws.com/prod/api/v1'
};
export const API_ROOT = API_ROOTS[STAGE];

// USERVICE API_IMAGE
const API_IMAGES = {
  local: 'http://localhost:8015', // 'https://dev.uservice.realm-global.com', //'http://localhost:8015', //
  dev: 'https://dev.uservice.realm-global.com',
  staging: 'https://stage.uservice.realm-global.com',
  prod: 'https://prod.uservice.realm-global.com'
};
export const API_IMAGE = API_IMAGES[STAGE];

// API_STRIPE_PUBLIC_KEY
const STRIPE_KEYS = {
  local: 'pk_test_FmxIZjtsLhSa7zAvaMM9iVUj00Kh107Z7G',
  dev: 'pk_test_FmxIZjtsLhSa7zAvaMM9iVUj00Kh107Z7G',
  staging: 'pk_test_XThGToWLKRRQxaRwJtpaXLt800z5CLacSp',
  prod: 'pk_live_93zkdFdctOA1VPcjLdBaQMqD'
};
export const API_STRIPE_PUBLIC_KEY = STRIPE_KEYS[STAGE];

// TRIBE_ROOT
const TRIBE_ROOTS = {
  local: 'https://realm-dev.tribe.so',
  dev: 'https://realm-dev.tribe.so',
  staging: 'https://realm-stage.tribe.so',
  prod: 'https://community.realm-global.com'
};
export const TRIBE_ROOT = TRIBE_ROOTS[STAGE];

// REALM_ASSETS_BUCKET
const ASSET_BUCKETS = {
  local: 'https://realm-assets-dev.s3.amazonaws.com',
  dev: 'https://realm-assets-dev.s3.amazonaws.com',
  staging: 'https://realm-assets-stage.s3.amazonaws.com',
  prod: 'https://realm-assets-prod.s3.amazonaws.com'
};
export const REALM_ASSETS_BUCKET = ASSET_BUCKETS[STAGE];

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_MAINTENANCE_MODE = false;
export const IS_TEMP_MAINTENANCE_MODE = false;

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 20; // 20 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 5; // after 5 additional minutes, force logout
export const EXPIRE_LOGOUT_DURATION = 1000 * 60 * 90; // after 90 minutes from last update, force logout
export const IMPERSONATION_LOGOUT_DURATION = 1000 * 60 * 30; // after 30 minutes of impersonation, force logout

// PIE_COLORS
export const PIE_COLORS_HI = ['#00b559', '#2b2d3c'];
export const PIE_COLORS_MED = ['#42d58a', '#2b2d3c'];
export const PIE_COLORS_LOW = ['#9af0c4', '#2b2d3c'];
export const PIE_COLORS_MY_VS_OTHER = ['#5f467f', '#479acc', '#2b2d3c']; // purple button blue

export const subscriptionTypes = {
  TEAM: 'Team',
  AGENT: 'Agent',
  LISTINGS: 'ItemsForSale',
  ITEMS: 'ItemsForSale'
};

// XXX TODO: Before changing this setting, implement any image scaling on upload
export const MAX_UPLOAD_MEGABYTES = '5';
export const MAX_UPLOAD_SIZE = MAX_UPLOAD_MEGABYTES * 1048576;

const mixPanelTokens = {
  localhost: 'ae521746749353d7d5a5dbeb040d7b49',
  dev: 'ae521746749353d7d5a5dbeb040d7b49',
  staging: '356f275ef713314033db4e02b374c29b',
  agent: '51b0e10bfd1829a0e042b8784dbf949c'
};

export const MIXPANEL_TOKEN = mixPanelTokens[SUBDOMAIN];
export const MIXPANEL_DEBUG = SUBDOMAIN !== 'agent';

const amityKeys = {
  localhost: 'b0e9eb533ed9a1364f61de1e505947d983008fe5bf603a79',
  dev: 'b0e9eb533ed9a1364f61de1e505947d983008fe5bf603a79',
  staging: 'b0e9ed5c3cd8a2661f368a4f575b15dad25cdee7bb346c79',
  agent: 'b0e9ec086bd9a5674c31df4a5500148fd6088bb3bc363c7a'
};

export const AMITY_PUBLIC_KEY = amityKeys[SUBDOMAIN];

const amityDefaultCommunity = {
  localhost: '65394337211b9204fd4940ee',
  dev: '65394337211b9204fd4940ee',
  staging: '655b9f2e34d2f74917a98d2b',
  agent: '655b9f5c402cd0b35b5b5058'
};

export const AMITY_DEFAULT_COMMUNITY = amityDefaultCommunity[SUBDOMAIN];

export const VERSION = appVersion.version; //NH mixpanel pageload update

